.callout {
  color: var(--card-text-color);
  /* gap: calc(var(--gap-spacing) * 1.5); */
  background-color: var(--card-bg-color);
  /* padding: var(--card-padding); */
  border-radius: var(--card-radius);
  outline-color: var(--card-outline-color);
  outline-style: solid;
  outline-width: 1px;
  box-shadow: var(--card-shadow);
  border: none;
}

.callout__content {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: var(--gap-spacing);
  line-height: 1.75;
}

.callout__title {
  font-size: var(--text-title-size);
  font-weight: var(--text-title-weight);
}

.callout__description {
  font-size: var(--text-body-size);
  font-weight: var(--text-body-weight);
  line-height: 1.35;
}

.callout__badge {
  display: inline-block;
  align-items: center;
  /* padding: var(--badge-padding); Override this for now on next line */
  padding: 0.5rem 1rem 0.6rem 1rem;
  border-radius: var(--badge-radius);
  border-width: 0;
  border-style: solid;
  border-color: var(--badge-border-color);
  box-shadow: var(--badge-shadow);
  font-size: var(--text-body-size);
  font-weight: var(--text-button-weight);
  line-height: 1;
  background-color: var(--badge-bg-color);
  color: var(--badge-text-color);
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--badge-outline-color);
}

.theme-style--book .callout__badge {
  border-bottom-width: 2px;
  padding-left: 0;
  padding-right: 0;
}

.callout-take-area {
  color: var(--text-color);
  display: flex;
  gap: calc(var(--gap-spacing) * 1.5);
  background-color: var(--bg-page-solid);
  background-image: var(--bg-page-gradient);
  padding: var(--card-padding);
  border-radius: var(--card-radius);
  border: none;
}
